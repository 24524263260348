import { render, staticRenderFns } from "./showTests.vue?vue&type=template&id=50c387b8&"
import script from "./showTests.vue?vue&type=script&lang=js&"
export * from "./showTests.vue?vue&type=script&lang=js&"
import style0 from "./showTests.vue?vue&type=style&index=0&id=50c387b8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports