import { render, staticRenderFns } from "./RequirementAssociateForm.vue?vue&type=template&id=cca70112&scoped=true&"
import script from "./RequirementAssociateForm.vue?vue&type=script&lang=js&"
export * from "./RequirementAssociateForm.vue?vue&type=script&lang=js&"
import style0 from "./RequirementAssociateForm.vue?vue&type=style&index=0&id=cca70112&prod&lang=scss&scoped=true&"
import style1 from "./RequirementAssociateForm.vue?vue&type=style&index=1&id=cca70112&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cca70112",
  null
  
)

export default component.exports