<template>
  <div>
    <div v-for="assc in associators" :key="assc.singular">
      <list-group-entity
        :label="assc.label"
        :entity-array="fields[`selected${getPlural(assc)}`]"
        :modal="`allocate-bn-${getPlural(assc)}-modal-single`"
        class="mb-1"
      />

      <GenericAssociator
        :id="`allocate-bn-${getPlural(assc)}-modal-single`"
        :fetch-filter-fn="fetchSharedModels"
        :fetch-fn="modelId => fetchComponents(modelId, `${getLeftOpts(assc)}`)"
        :update-fn="payload => linkComponents(payload, getPlural(assc))"
        :initial-prefilter="selectedModelComputed"
        :initial-list="fields[`selected${getPlural(assc)}`]"
        :instant-save="false"
        prefilter-label="name"
        :type-name="assc.label"
      >
        <template #left="cpt">
          <b-badge v-for="l in cpt.labels.filter(lb => lb !== 'Component')" :key="l" class="mr-1" variant="primary">
            ({{ l }})
          </b-badge>
          &nbsp;
          <abbr :title="cpt.name" class="ml-1 mr-1 text-ellipsis">{{ cpt.name }} </abbr>
        </template>
        <template #right="{ cpt, triggerSave }">
          <ComponentAllocatedListItem :component-badge="assc.singular" :component="cpt"
                                      :trigger-fn="triggerSave"
          />
        </template>
      </GenericAssociator>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import coreService from '@/libs/api-services/core-service'
import GenericAssociator from '@/components/Forms/GenericAssociator.vue'
import ComponentAllocatedListItem from '@/components/Forms/ComponentAllocatedListItem.vue'

export default {
  components: {
    GenericAssociator,
    ComponentAllocatedListItem,
    ListGroupEntity,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: '',
      name: '',
      fields: {
        selectedObjectives: [],
        selectedFunctions: [],
        selectedStandards: [],
        selectedEnablers: [],
        selectedBeneficiaries: [],
      },
      // TODO: Rename back to stereotype_list
      // composition_sterotypes_opts: ['Component', 'Performer', 'System', 'Human Actor', 'Service', 'Function', 'Process',
      //   'Activity', 'Capability', 'Objective', 'Constraint', 'Standard', 'Agreement', 'Reference', 'Objective',
      //   'Measure', 'Resource', 'Information and Data', 'Materiel', 'Skill'],
      quill_editor_options: {
        theme: 'snow',
        modules: {
          toolbar: '#toolbar',
        },
      },
      allModels: [],
      selectedModel: null,
      associators: [
        {
          singular: 'Enabler',
          label: 'Enablers',
          leftOpts: 'performers',
        },
        {
          singular: 'Beneficiary',
          label: 'Beneficiaries',
          plural: 'Beneficiaries',
          leftOpts: 'performers',
        },
        { singular: 'Objective', label: 'Objectives' },
        { singular: 'Standard', label: 'Standards / References' },
      ],
    }
  },
  computed: {
    ...mapState({
      behaviourNode: state => state.behaviours.selectedBehaviourNode,
      selected_bt: state => state.behaviours.selectedBehaviourTree,
    }),
    ...mapState({
      performers: state => state.domainModel.performers,
      functions: state => state.domainModel.functions,
      objectives: state => state.domainModel.objectives,
      standards: state => state.domainModel.standards,
      selectedModelComputed: state => state.model,
    }),
    ...mapGetters({
      stereotypes: 'constants/stereotypes',
    }),
  },
  watch: {
    behaviourNode(newVal) {
      this.fillFields(newVal)
    },
  },
  mounted() {
    this.$store.dispatch('domainModel/getComponents')
    this.fillFields(this.behaviourNode)
  },
  methods: {
    getPlural(obj) {
      return obj?.plural || `${obj.singular}s`
    },
    getLeftOpts(obj) {
      return obj?.leftOpts === '' ? '' : obj?.leftOpts || this.getPlural(obj)
    },
    async fetchComponents(modelId, subtype) {
      await this.$store.dispatch('domainModel/getComponentsForModel', { modelId, subtype: '' })
      if (modelId && this.$store.state.model.id !== modelId) {
        // now returns all components, not just the subtype
        return this.$store.state.domainModel[modelId].components
      }
      return this.$store.state.domainModel.components
    },
    async fetchSharedModels() {
      return [this.$store.state.model, ...await coreService.modelApi.getLinkedModels(this.$store.state.model.id)]
    },
    async linkComponents(payload, allocationType) {
      this.fields[`selected${allocationType}`] = payload.map(x => ({
        id: x.id,
        name: x.name,
        justification: x.justification,
      }))
      this.allocateFn(allocationType)
    },
    fillFields(n) {
      const t = this.fields
      t.selectedObjectives = n.objectives
      t.selectedEnablers = n.enablers
      if (n.beneficiaries) {
        t.selectedBeneficiaries = n.beneficiaries
      } else {
        t.selectedBeneficiaries = []
      }
      if (n.standards) {
        t.selectedStandards = n.standards
      } else {
        t.selectedStandards = []
      }
    },
    allocateFn(allocationType) {
      const payload = {
        model: this.$store.state.model.id,
        overwrite: true,
      }
      payload[allocationType.toLowerCase()] = this.fields[`selected${allocationType}`].map(x => ({
        id: x.id,
        justification: x.justification,
      }))
      this.$http
        .post(`/api/v2/behaviour/nodes/${this.behaviourNode.details.id}/${allocationType.toLowerCase()}`, payload)
        .then(() => {
          this.$store.dispatch('behaviours/selectBehaviourNode', this.behaviourNode.details.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${allocationType} associated`,
              text: `Successfully associated ${allocationType} with the BehaviourNode`,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(r => {
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Failed to associate ${allocationType}`,
              text: `An error occurred when attempting to associate Standards with the BehaviourNode.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    routeToOntology(node) {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: this.id },
        },
      )
    },
    deselectPerformers() {
      this.fields.selected_performers = []
    },
  },
}
</script>
