<template>
  <div class="p-1 m-0 permissionBorder">
    <h6>{{ title || "Permissions" }}</h6>
    <p>
      <span class="text-primary">
        {{ nodeType }}
      </span>
      <span class="text-secondary ml-1">
        {{ nodeName }}
      </span>
    </p>
    <div v-if="!addMode" class="flex-sm-row">
      <span v-for="(p, index) in permissions" :key="index" class="badge rounded-pill bg-primary mr-1">
        {{ p.role }}
        <feather-icon class="ml-1" :icon="getIcon(p)" />
      </span>
      <b-button v-if="isAdmin" class="p-1" variant="outline-primary" @click="() => addMode = !addMode">
        <feather-icon icon="EditIcon" />
        {{ permissions.length > 0 ? "Edit" : "Add" }}
      </b-button>
    </div>
    <div v-if="addMode" class="d-flex flex-row">
      <v-select
        id="select-permissions-dropdown"
        v-model="permissions"
        label="role"
        class="select-class d-inline-block flex-fill"
        multiple
        :options="availableRoles"
      >
        <template v-slot:option="option">
          <span>{{ option.role }} </span>
          <feather-icon class="ml-1" :icon="getIcon(option)" />
        </template>
        <template #selected-option="option">
          {{ option.role }}
          <feather-icon class="ml-1" :icon="getIcon(option)" />
        </template>
      </v-select>
      <b-button class="ml-1" size="sm" variant="secondary" @click="discardChanges()">
        Discard
      </b-button>
      <b-button v-if="!hideSaveButton" class="ml-1" size="sm" variant="primary" @click="savePermissions()">
        Save
      </b-button>
    </div>
    <div v-if="addMode" class="d-flex flex-row mt-1">
      <b-form-checkbox v-model="shouldApplyToChildren">
        Apply these permissions to this {{ nodeType }} and all its descendants
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { difference } from 'lodash'
import vSelect from 'vue-select'
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const icons = {
  '': '',
  r: 'EyeIcon',
  rw: 'Edit3Icon',
  deny: 'EyeOffIcon',
  limited: 'FileIcon',
}

export default {
  name: 'Permissions',
  components: {
    vSelect,
  },
  props: {
    title: String(''),
    nodeType: String(''),
    nodeName: String(''),
    nodeId: String(''),
    hideSaveButton: Boolean(false),
  },
  setup(props) {
    const isAdmin = ref(false)
    const shouldApplyToChildren = ref(false)
    const permissions = ref([])
    const initialPermissions = ref([])
    const permissionLevels = ref({})
    const availableRoles = ref([])
    const addMode = ref(false)
    async function savePermissions() {
      if (!isAdmin) return
      if (permissions.value === initialPermissions.value) return
      const perms = permissions.value.map(p => p.role)
      const initialPerms = initialPermissions.value.map(ip => ip.role)
      try {
        const response = await coreService.adminCliApi.setNodePermissions(props.nodeId,
          {
            node_id: props.nodeId,
            children: shouldApplyToChildren.value,
            read_write: true, // currently ignored, this is based on Role settings instead
            roles_adding: difference(perms, initialPerms),
            roles_removing: difference(initialPerms, perms),
          })
        addMode.value = !addMode.value
        const added = response?.added?.reduce((p, c) => p + c.added, 0)
        const removed = response?.removed?.reduce((p, c) => p + c.removed, 0)
        initialPermissions.value = permissions.value
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Permissions',
            text: `Added ${added} permissions, removed ${removed}`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Permissions',
            text: `Failed changing permissions ${e}`,
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    }
    async function discardChanges() {
      permissions.value = initialPermissions.value
      addMode.value = false
    }
    function getIcon(option) {
      return icons[option.permission]
    }
    return {
      isAdmin,
      permissions,
      permissionLevels,
      initialPermissions,
      addMode,
      availableRoles,
      shouldApplyToChildren,
      getIcon,
      savePermissions,
      discardChanges,
    }
  },
  async mounted() {
    try {
      const results = (await coreService.roleManagementApi.getAllRoles())
      const allRoles = results.map(d => d.keycloak_role.name)
      // get the privileges and permission for all roles
      const tempPerms = {}
      results.forEach(r => {
        // permission levels are needed here for the icon selection
        tempPerms[r.keycloak_role.name] = r?.neo4j_privileges ?? 0
      })
      this.availableRoles = allRoles.map(r => ({ role: r, permission: tempPerms[r] }))
      // get the privileges for just this node
      const result = await coreService.adminCliApi.getNodePermissions(this.nodeId)
      const permResult = result.data.filter(d => d !== '').map(p => p.split('_')[1] || p)
      this.permissions = this.availableRoles.filter(r => permResult.includes(r.role))
      this.initialPermissions = this.permissions
      this.isAdmin = true
    } catch {
      this.isAdmin = false
    }
  },
}
</script>

<style scoped>
.permissionBorder {
    border: 1px solid #aaaaaa44;
    margin: 0 !important;
    border-radius: 0.5rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
