<template>
  <b-modal
    :id="id"
    :title="title"
    size="lg"
    lazy
    @ok="submitFunction"
    @show="getData"
  >
    <!--{{ all_reqs }}-->
    <b-form-group>
      <b-row align-h="between">
        <b-col cols="3">
          <!-- Event -->
          <label for="bn-type-select">Type:</label>
          <b-form-select id="bn-type-select" v-model="type" :options="type_options" />
        </b-col>
        <b-col cols="3">
          <!-- Operator -->
          <label for="bn-operator-select">Operator:</label>
          <b-form-select id="bn-operator-select" v-model="operator" :options="operator_options" />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group>
      <!--<pre>{{ selected_performer }}</pre>-->
      <div v-if="type !== 'FunctionNode'">
        <label for="subject-input">
          <span v-if="type !== 'Quantification'">Subject/Performer:</span>
          <span v-else>For Each:</span>
        </label>
        <b-form-select
          id="perf-select"
          v-model="selected_performers"
          :options="performers"
          :select-size="4"
          multiple
        />
        <b-row>
          <b-col>
            <div>
              <label for="instance-input">
                Instance Name:
              </label>
              <b-form-input id="instance-input" v-model="instance" placeholder="Enter instance name" />
            </div>

          </b-col>
          <b-col>
            <div v-if="type !== 'Quantification'">
              <label for="attribute-input">
                Attribute:
              </label>
              <b-form-input id="attribute-input" v-model="attribute" placeholder="Enter the attribute/property" />
            </div>

          </b-col>
        </b-row>
      </div>

      <div v-if="type !== 'Quantification'">
        <label for="action-input">
          <span v-if="type !== 'Input' && type !== 'Output'">Action:</span>
          <span v-else>Data/Resource:</span>
        </label>
        <!--<pre>{{ negate }}</pre>-->
        <b-row>
          <b-col cols="10">
            <b-form-input id="action-input" v-model="action" placeholder="Enter the action to perform" @change="parseAction" />
          </b-col>
          <b-col cols="2">
            <b-form-checkbox
              v-model="negated"
              class="mt-50"
              :value="true"
              :unchecked-value="false"
            >
              Negate
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>

    </b-form-group>

    <hr>
    <div v-if="type !== 'FunctionNode' && type !== 'Quantification'">
      <b-form-group>
        <h4 class="mb-1">
          Objects
        </h4>
        <!--<b-row class="mb-1">-->
        <!--  <b-col cols="3">-->
        <!--    <b-form-input placeholder="Preposition" />-->
        <!--  </b-col>-->
        <!--  <b-col>-->
        <!--    <b-form-input placeholder="Object Name" />-->
        <!--  </b-col>-->
        <!--</b-row>-->

        <div
          v-for="(object, index) in objects"
          :id="object.id"
          :key="object.id"
          class="mb-1"
        >
          <b-row>
            <b-col cols="3">
              <b-form-input
                v-model="object.pre"
                placeholder="Preposition"
              />
            </b-col>
            <b-col cols="9">
              <b-form-input
                v-model="object.text"
                list="object-input-list-ifb"
                placeholder="Object Name"
              />
              <b-form-datalist id="object-input-list-ifb">
                <option v-for="performer in performers" :key="performer.value">
                  {{ performer.text }}
                </option>
              </b-form-datalist>

            </b-col>
          </b-row>

          <b-row class="mt-50">
            <b-col>
              <b-form-input
                v-model="object.instance"
                placeholder="Instance Name"
              />
            </b-col>
            <b-col>
              <b-form-input
                v-model="object.attribute"
                placeholder="Object Attribute"
              />
            </b-col>

            <b-col
              cols="1"
              class="pl-0"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="h-100"
                size="sm"
                @click="removeObject(index)"
              >
                <feather-icon
                  icon="XIcon"
                />
              </b-button>
            </b-col>
          </b-row>

        </div>

        <b-row align-h="center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            @click="repeatObject"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Add Object</span>
          </b-button>
        </b-row>
      </b-form-group>
      <b-row>
        <b-col>
          <!-- Requirements -->
          <list-group-requirement
            label="Requirements"
            :entity-array="selected_reqs"
            modal="associator-generic-requirements-function-behaviour"
          />
        </b-col>
        <b-col>
          <!-- Issues -->
          <list-group-issue
            label="Issues"
            :entity-array="selected_issues"
            modal="associator-generic-issues-function-behaviour"
          />
        </b-col>
      </b-row>
    </div>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button v-if="!creating" variant="primary" class="float-right" @click="ok()">
        Instantiate
      </b-button>
      <b-button v-else variant="primary" class="float-right" @click="ok()">
        Instantiating Function <b-spinner type="grow" small />
      </b-button>
      <b-button class="float-right mr-1" @click="cancel()">
        Cancel
      </b-button>
    </template>
    <!--<Associator-->
    <!--  id="associate-bn-req-modal"-->
    <!--  title="Associate Behaviours with Requirements"-->
    <!--  left-label="Requirements"-->
    <!--  :left-opts="all_reqs.map(x=>{return{value:x.id, text:x.display_id+' - '+x.text, display_id: x.display_id, object_text: x.text, priority: x.priority}})"-->
    <!--  right-label="Requirements"-->
    <!--  :right-opts="selected_reqs.map(x=>{return{value:x.id, text:x.display_id+'-'+x.text, display_id: x.display_id, object_text: x.text, priority: x.priority}})"-->
    <!--  @ok="linkBNRequirements"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="associate-bn-iss-modal"-->
    <!--  title="Associate Behaviours with Issues"-->
    <!--  left-label="Issues"-->
    <!--  :left-opts="all_issues.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Entities"-->
    <!--  :right-opts="selected_issues.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkBNIssues"-->
    <!--/>-->

    <!-- Requirements -->
    <associator-generic
      name="Requirements"
      suffix="-function-behaviour"
      :associated-items="selectedAssociatorReqs"
      :all-items="allRequirements.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.display_id,
          },
          text: `${item.display_id}. ${item.text.replace(/<\/?[^>]+(>|$)/g, '')}`,
        }
      })"
      @associated="linkBNRequirements"
    />

    <!-- Issues -->
    <associator-generic
      name="Issues"
      suffix="-function-behaviour"
      :associated-items="selectedAssociatorIssues"
      :all-items="allIssues.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.display_id,
          },
          text: `${item.display_id}. ${item.name}`,
        }
      })"
      @associated="linkBNIssues"
    />
  </b-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import ListGroupIssue from '@/components/Forms/ListGroups/ListGroupIssue.vue'
// import Associator from '@/components/Forms/M_Associator.vue'
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'

export default {
  name: 'InstantiateFunction',
  directives: {
    Ripple,
  },
  components: {
    ListGroupIssue,
    ListGroupRequirement,
    // Associator,
    AssociatorGeneric,
  },
  props: {
    id: {
      type: [String],
      required: true,
    },
    title: {
      type: [String],
      default: 'Add Behaviour',
    },
    p_type: {
      type: [String],
      default: 'Event',
    },
    p_direction: {
      type: [String],
      default: 'after',
    },
    p_performers: {
      type: Array,
      default: () => [],
    },
    p_behaviour: {
      type: [String],
      default: '',
    },
    p_reqs: {
      type: Array,
      default: () => [],
    },
    p_iss: {
      type: Array,
      default: () => [],
    },

  },
  data: () => ({
    performers: [],
    // Top Row
    type: 'Event',
    type_options: [
      { value: 'Event', text: 'Event' },
      { value: 'State', text: 'State' },
      { value: 'Selection', text: 'Condition' },
      { value: 'GuardedEvent', text: 'Guarded Event' },
      { value: 'Input', text: 'Input' },
      { value: 'Output', text: 'Output' },
      { value: 'Assertion', text: 'Assertion' },
      { value: 'Quantification', text: 'Quantification' },
      { value: 'FunctionNode', text: 'Abstract Function' },
    ],
    operator: 'no_operator',
    // Inputs
    selected_performer: '',
    selected_performers: [],
    action: '',
    action_fn: '',
    negated: false,
    attribute: '',
    instance: '',
    // Form Repeater
    objects: [],
    nextObjectId: 0,
    // Associators
    all_reqs: [],
    all_issues: [],
    selectedAssociatorReqs: [],
    selectedAssociatorIssues: [],
    // -- Filtered arrays
    allRequirements: [],
    allIssues: [],
    // -- Selected
    selected_reqs: [],
    selected_issues: [],
    parseResults: {},
    creating: false,
    // quill_editor_options: {
    //   theme: 'snow',
    // },
  }),
  computed: {
    ...mapState({
      selected_bn: state => state.behaviours.selectedBehaviourNode,
      selected_bt: state => state.behaviours.selectedBehaviourTree,
    }),
    ...mapGetters({
      behaviourOperators: 'constants/behaviourOperators',
    }),
    operator_options() {
      return this.behaviourOperators.map(x => ({
        value: x.id,
        html: `${x.display} - ${x.text}`,
      }))
    },
  },
  mounted() {
    // this.labels = this.selected_entity.context.details.labels
    this.type = this.p_type
    this.objects = []
    this.selected_performers = this.p_performers
    this.action = this.p_behaviour
    this.selected_reqs = this.p_reqs
    this.selected_issues = this.p_iss
  },
  methods: {
    // -- API -- //
    getData() {
      const modelId = this.$store.state.model.id
      this.$http.get('/api/v2/domain_model/get_composition_subtree', { params: { model: modelId } })
        .then(({ data }) => {
          this.performers = data.nodes.map(x => ({
            value: x.id,
            text: x.name,
          }))
        })
      this.$http.get('/api/v2/requirements/get_requirements_simple', { params: { model: modelId } }).then(({ data }) => {
        this.all_reqs = data
        this.selected_reqs = this.all_reqs.filter(x => this.p_reqs.includes(x.id))
        this.filterRequirements()
        this.selectedAssociatorReqs = this.selected_reqs.map(item => ({
          value: {
            id: item.id,
            toSortBy: item.display_id,
          },
          text: `${item.display_id}. ${item.object_text?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''}`,
        }))
      })
      this.$http.get('/api/v2/issues/get_issues_simple', { params: { model: modelId } }).then(({ data }) => {
        this.all_issues = data
        this.selected_issues = this.all_issues.filter(x => this.p_iss.includes(x.id))
        this.filterIssues()
        this.selectedAssociatorIssues = this.selected_issues.map(item => ({
          value: {
            id: item.id,
            toSortBy: item.display_id,
          },
          text: `${item.display_id}. ${item.name}`,
        }))
      })
      this.action = this.p_behaviour
      this.selected_performers = this.p_performers
      this.parseAction()
      this.objects = []
    },
    parseAction() {
      if (this.type !== 'FunctionNode') {
        const params = {
          model: this.$store.state.model.id,
          text: this.action,
        }
        this.$http.post('/api/v2/behaviour/parse_behaviour_text', params)
          .then(({ data }) => {
            this.parseResults = data
            if (data.action_candidates && data.action_candidates.length > 0) {
            // TODO: Handle cases with multiple actions (therefore multiple behaviour nodes to be created) -
            //  This is a bad practice, but maybe one that should be supported
              this.action = data.action_candidates[0].name
              this.action_fn = data.action_candidates[0].fn
              this.objects = []
              data.object_candidates.forEach(o => {
                let useName = o.name
                if (data.possible_matches[useName].matches.length > 0) {
                  useName = data.possible_matches[useName].matches[0].name
                } else if (data.possible_matches[useName].synonyms.length > 0) {
                  useName = data.possible_matches[useName].synonyms[0].name
                }
                this.objects.push({
                  pre: o.preposition,
                  text: useName,
                })
              })
            }
          })
      }
    },
    submitFunction(evt) {
      evt.preventDefault()
      this.creating = true
      const params = this.parseResults
      params.model = this.$store.state.model.id
      params.subjects = this.selected_performers
      params.actions = [{ name: this.action, fn: this.action_fn }]
      params.type = this.type
      params.operator = this.operator
      params.negated = this.negated
      params.attribute = this.attribute
      params.instance_name = this.instance
      params.objects = this.objects.map(e => ({
        preposition: e.pre, object: e.text, attribute: e.attribute, instance_name: e.instance,
      }))
      params.parent = this.selected_bn.details.id
      params.parent_rel = 'sequence'
      params.bts = [this.selected_bt.id]
      params.function = ''
      params.operator = this.operator
      params.direction = this.p_direction
      params.function = this.p_behaviour
      params.precondition = []
      params.postcondition = []
      params.requirements = this.selected_reqs.map(x => x.id)
      params.issues = this.selected_issues.map(x => x.id)
      if (params.type === 'FunctionNode') {
        if (params.subject !== '' && this.action === '') {
          params.actions = this.action_fn && this.action_fn !== '' ? this.action_fn : params.subjects
          params.subject = ''
          params.subjects = []
        } else {
          params.subject = ''
          params.subjects = []
        }
      } else if (params.type === 'Quantification') {
        params.actions = [{ name: '||', fn: '' }]
        params.action = '||'
      }
      console.log('Creating SAO with: ', params)
      this.$http.post('/api/v2/behaviour/create_sao_behaviour', params)
        .then(({ data }) => {
          this.$bvModal.hide('instantiate-fn-modal')
          this.$emit('instantiated', data)
          this.creating = false
        })
    },
    // -- Utility -- //
    repeatObject() {
      this.objects.push({
        id: this.nextObjectId += 1,
        pre: '',
        instance: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeObject(index) {
      this.objects.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    linkBNRequirements(reqs) {
      const temp = this.findItemById(reqs, this.all_reqs)

      this.selected_reqs = temp.map(x => ({
        id: x.id,
        display_id: x.display_id,
        object_text: x.text,
      }))
      this.filterRequirements()
    },
    linkBNIssues(issues) {
      const temp = this.findItemById(issues, this.all_issues)

      this.selected_issues = temp.map(x => ({
        id: x.id,
        name: x.name,
        display_id: x.display_id,
      }))
      this.filterIssues()
    },
    findItemById(toAssociate, allItems) {
      // Associators generic spit out arrays of just IDs
      // all link methods need ID and Name
      // this method will find the full object by ID from the all items array
      function sortFunction(value) {
        return allItems.find(item => (item.id === value))
      }

      return toAssociate.map(sortFunction)
    },
    filterRequirements() {
      // Filter duplicates for associators
      this.allRequirements = this.all_reqs.map(x => ({ ...x }))
      this.selected_reqs.forEach((elementA, indexA) => {
        this.allRequirements.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allRequirements.splice(indexB, 1)
          }
        })
      })
    },
    filterIssues() {
      // Filter duplicates for associators
      this.allIssues = this.all_issues.map(x => ({ ...x }))
      this.selected_issues.forEach((elementA, indexA) => {
        this.allIssues.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allIssues.splice(indexB, 1)
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.modal-footer-behaviour {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
