<template>
  <!--
  Associator Generic should only perform as an UI tool to output a list of items to associate
  It *will not* perform the associate function/API call itself,
    but provide the means for the parent component

  Implementation:
  <associator-generic
    name="" (What are you associating with? Requirements, Issues, etc. Note: Also is used to make the ID of the modal)
    suffix="" (Suffix to the element ID to avoid duplicate ID conflicts)
    :associated-items="" (List of items that are currently associated)
    :all-items="" (Full list of all items that can be associated)
    @associated="" (The method to run when 'Associate' is clicked, associator will output an array to this method)
  />

  Format of arrays:
  const allItems = [
    {
      value: {
        id: 'UID, required, String',
        toSortBy: 'Property to sort the array by, optional (will use `text` instead), String or Number',
      },
      text: 'Text to display in the list, required, String (No HTML)',
    },
  ]
  -->
  <b-modal
    :id="`associator-generic-${name.toLowerCase()}${suffix}`"
    :title="`Associate ${name}`"
    size="lg"
    lazy
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    @show="filterOutDuplicateItems"
    @ok="submitAssociatedItems"
  >
    <!-- Available Items -->
    <b-row>
      <b-col>
        <label for="allItems">
          <b-badge class="bg-info">{{ localAllItems.length }}</b-badge> :
          Available {{ name }}
        </label>
        <b-form-select
          id="allItems"
          v-model="selectedAllItems"
          :options="localAllItems"
          :select-size="6"
          multiple
        />
      </b-col>
    </b-row>

    <!-- Association and Un-association buttons -->
    <b-row class="mx-auto">
      <b-col>
        <b-button-group class="w-100">
          <!-- Remove Association -->
          <b-button
            variant="flat-danger"
            :disabled="selectedAssociatedItems.length === 0"
            @click="remove"
          >
            <feather-icon icon="ArrowUpIcon" size="24" />
          </b-button>

          <!-- Add Association -->
          <b-button
            variant="flat-success"
            :disabled="selectedAllItems.length === 0"
            @click="add"
          >
            <feather-icon icon="ArrowDownIcon" size="24" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <!-- Associated Items -->
    <b-row class="mb-2">
      <b-col>
        <label for="associatedItems">
          <b-badge class="bg-danger">{{ localAssociatedItems.length || 0 }}</b-badge> :
          Associated {{ name }}
        </label>
        <b-form-select
          id="associatedItems"
          v-model="selectedAssociatedItems"
          :options="localAssociatedItems"
          multiple
          :select-size="6"
        />
      </b-col>
    </b-row>

    <!-- Footer -->
    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="resetFields(); cancel()">
        Cancel
      </b-button>
      <b-button variant="success" @click="ok()">
        <span>Associate {{ name }}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'AssociatorGeneric',
  // Props must be provided for the associator to work
  // Associator must not send the API call to associate, but to return an array of items *to* associate
  props: {
    allItems: {
      required: true,
      type: Array,
    },
    associatedItems: {
      required: true,
      type: Array,
    },
    name: {
      type: String,
      default: 'Items',
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    // Declare the variables to be used in setup code
    // -- All Items
    const localAllItems = ref([])
    const selectedAllItems = ref([])
    watch(() => props.allItems, () => {
      localAllItems.value = [...props.allItems]
    }, { immediate: true })

    // -- Associated Items
    const localAssociatedItems = ref([])
    const selectedAssociatedItems = ref([])
    watch(() => props.associatedItems, () => {
      localAssociatedItems.value = [...props.associatedItems]
    }, { immediate: true })

    // Functions/Methods
    // -- Sort at least once
    localAllItems.value.sort(sortArrayByDisplayID)
    localAssociatedItems.value.sort(sortArrayByDisplayID)
    filterOutDuplicateItems()
    // -- Move items
    function add() {
      selectedAllItems.value.forEach((elementA, indexA) => {
        localAllItems.value.forEach((elementB, indexB) => {
          if (elementA === elementB.value) {
            localAllItems.value.splice(indexB, 1)
            localAssociatedItems.value.push(elementB)
          }
        })
      })
      selectedAllItems.value = []
      localAssociatedItems.value.sort(sortArrayByDisplayID)
    }
    function remove() {
      selectedAssociatedItems.value.forEach((elementA, indexA) => {
        localAssociatedItems.value.forEach((elementB, indexB) => {
          if (elementA === elementB.value) {
            localAssociatedItems.value.splice(indexB, 1)
            localAllItems.value.push(elementB)
          }
        })
      })
      selectedAssociatedItems.value = []
      localAllItems.value.sort(sortArrayByDisplayID)
    }
    // -- Sort Array
    function sortArrayByDisplayID(a, b) {
      const first = a.value.toSortBy ? a.value.toSortBy : a.text
      const second = b.value.toSortBy ? b.value.toSortBy : b.text
      if (first < second) {
        return -1
      }
      if (first > second) {
        return 1
      }
      return 0
    }
    // -- Filter Arrays
    function filterOutDuplicateItems() {
      localAssociatedItems.value.forEach((elementA, indexA) => {
        // console.log('Element A: ', elementA)
        localAllItems.value.forEach((elementB, indexB) => {
          // console.log('Element B: ', elementA)
          if (elementA.value.id === elementB.value.id) {
            // console.log('Deleted')
            localAllItems.value.splice(indexB, 1)
          }
        })
      })
    }
    // -- Submit
    function submitAssociatedItems() {
      const payload = localAssociatedItems.value.map(x => (x.value.id))
      emit('associated', payload)
    }
    // -- Cancel
    function resetFields() {
      localAllItems.value = [...props.allItems]
      localAssociatedItems.value = [...props.associatedItems]
    }

    return {
      // Variables
      // -- Local instances of props
      localAllItems,
      localAssociatedItems,
      // -- Items to be moved from one array to the other
      selectedAllItems,
      selectedAssociatedItems,
      // Functions/Methods
      // -- Filter
      filterOutDuplicateItems,
      // -- Move items
      add,
      remove,
      // -- Submit
      submitAssociatedItems,
      // -- Cancel
      resetFields,
    }
  },
}
</script>
