<template>
  <div>
    <b-badge class="mr-1" variant="primary">
      {{ componentBadge }}
    </b-badge>
    <abbr :title="cpt.qualified_name ? cpt.qualified_name : cpt.name" class="mr-1 text-ellipsis">
      {{ cpt.qualified_name ? cpt.qualified_name : cpt.name }}
    </abbr>
    <div class="form-label-group mt-2 mb-0">
      <b-form-textarea
        id="txtJustification"
        v-model="cpt.justification"
        :rows="1"
        size="sm"
        placeholder="Justification"
        title="Justification"
        @change="triggerSave"
        debounce="500"
      />
      <label for="txtJustification">Justification</label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ComponentAllocatedListItem',
  props: {
    componentBadge: { type: String, default: '' },
    component: { type: Object, required: true },
    triggerFn: { type: Function, required: true },
  },
  setup(props) {
    return {
      cpt: props.component,
      triggerSave: props.triggerFn,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
